import { Box, Skeleton } from '@mui/material'
import React from 'react'
import LinkBar from '../links'
import useActions from '../hooks/useActions'
import { useGroup } from '../../../hooks'
import { BROWSER } from '../../../services/cognito/userGroups'
import makeStyles from '@mui/styles/makeStyles'
import { useConnectionContext } from '@emerald-works/react-event-bus-client'

const useStyles = makeStyles(({ spacing }) => ({
  buttonbar: {
    gap: spacing(1)
  }
}))

const ActionBar = () => {
  const classes = useStyles()
  const { isInGroup } = useGroup()
  const { filterActions } = useActions()
  const { isConnected } = useConnectionContext()
  if (!isConnected) {
    return (
      <Box display='flex' flexDirection='row' alignItems='center' gap='0px'>
        <Skeleton variant='rect' width='100px' height='30px' />
        <Skeleton variant='rect' width='100px' height='30px' />
        <Skeleton variant='rect' width='100px' height='30px' />
        <Skeleton variant='rect' width='100px' height='30px' />
      </Box>
    )
  }
  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      <LinkBar>{filterActions('linkbar')}</LinkBar>
      {filterActions('actionbar')}
      <LinkBar
        variant={isInGroup(BROWSER) ? 'fixedWidthItems' : 'variableWidthItems'}
        classes={{ root: classes.buttonbar }}
      >
        {filterActions('buttonbar')}
      </LinkBar>
    </Box>
  )
}

export default ActionBar
