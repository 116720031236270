import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { googleTranslateLanguages } from './languages'

const initialState = {
  content: undefined,
  isUrlAdjusted: false,
  list: [],
  modal: undefined,
  language: 'en',
  translation: undefined,
  isTranslating: false,
  allLanguages: googleTranslateLanguages,
  hasRecommend: false,
  recommendCount: 0,
  isExclusive: false,
  isPreview: false,
  isMeteringEnabled: false,
  meteringLimit: undefined,
  meteringUsed: undefined,
  isLoadingExtraInfo: false
}

export const content = createSlice({
  name,
  initialState,
  reducers: {
    setContent: (state, { payload }) => {
      if (!payload?.content) return { ...initialState }

      if (state?.content?.pk === payload.content.pk) {
        state.content = { // Already set, updating content OR translating the same article.
          ...state.content,
          ...payload.content
        }
      } else {
        state.content = { ...payload.content }
      }
      state.isPreview = payload.isPreview
      state.isMeteringEnabled = payload.isMeteringEnabled
      state.meteringLimit = payload.meteringLimit
      state.meteringUsed = payload.meteringUsed
      state.isExclusive = payload.isExclusive
    },
    setExtraInfo: (state, { payload }) => {
      const { content: extraInfo } = payload
      state.isLoadingExtraInfo = false
      state.content = {
        ...state.content,
        ...extraInfo,
        extraInfo: true
      }
    },
    setIsLoadingExtraInfo: (state, { payload }) => {
      state.isLoadingExtraInfo = payload
    },
    showModal: (state, { payload }) => {
      state.modal = payload
    },
    setIsTranslating: (state, { payload }) => {
      state.isTranslating = payload
    },
    setLanguage: (state, { payload }) => {
      state.language = payload
    },
    recommendToggle: (state, { payload }) => {
      const { hasRecommend, recommendCount } = payload

      state.content = {
        ...state.content,
        hasRecommend,
        recommendCount
      }
    },
    setIsUrlAdjusted: (state, { payload }) => {
      state.isUrlAdjusted = payload
    },
    reset: state => initialState
  }
})

export const {
  showModal,
  setContent,
  setLanguage,
  setIsTranslating,
  setIsLoadingExtraInfo,
  recommendToggle,
  setIsUrlAdjusted,
  setExtraInfo,
  reset
} = content.actions

export default content.reducer
