import React from 'react'
import { Divider, Grid } from '@mui/material'
import Container from '../container'
import LinkPanel from './link-panel'
import Legal from './legal'
import makeStyles from '@mui/styles/makeStyles'
import ContentLinks from './content-links'

const useStyles = makeStyles(({ palette, spacing }) => ({
  content: {
    paddingBottom: spacing(7),
    backgroundColor: palette.grey.shade[5]
  },
  divider: {
    backgroundColor: palette.grey.shade[20],
    marginBottom: spacing(7),
    width: '100%',
    maxWidth: 1440
  },
  dividerContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: spacing(5),
    paddingRight: spacing(5)
  }
}))

const PageFooter = () => {
  const classes = useStyles()

  return (
    <Container>
      <Grid
        container
        direction='column'
        alignItems='stretch'
        className={classes.content}
      >
        <Grid item>
          <ContentLinks />
        </Grid>
        <Grid item>
          <Container inset={2}>
            <div className={classes.dividerContainer}>
              <Divider className={classes.divider} />
            </div>
            <LinkPanel />
          </Container>
        </Grid>
      </Grid>
      <Grid item>
        <Legal />
      </Grid>
    </Container>
  )
}

export default PageFooter
